@import url(https://fonts.googleapis.com/css?family=Roboto:500);

button.action-item {
    padding: 0;
    margin-right: 8px;
}

span.page-link.ellipsis-text {
    display: none;
}

#status-fanpage {
    margin-top: 23px;
    margin-left: -35px;
}

button.action-item i {
    font-size: 17px !important;
}

.result_add {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #4dbd74;
}

table.rp tr > td:first-of-type {
    width: 114px;
}

table.rp tr td span {
    margin-left: 11px;
    color: #0250b1;
}

.start-to-count {
    width: 78px;
}

.start-to-count div {
    margin: auto;
}

.rp-body .table-responsive {
    margin-top: -14px;
}

table.rp td {
    border-bottom: 1px solid #c8ced3 !important;
    border-top: none !important;
}

a.profile {
    padding: 10px 24px;
}

#check_status_active {
    position: relative;
    margin-left: 0;
    margin-right: 9px;
}

.btn-actions {
    margin-right: 15px;
}

a.profile {
    padding: 10px 98px 10px 18px;
    display: inline-block;
}

.main_profile {
    padding: 0 !important
}

.table-responsive {
    margin-top: 17px;
}

i.fa.fa-search {
    margin-right: 4px;
}

ul.report-tag {
    list-style: none;
    margin-left: -39px;
}

.main-report-tag {
    margin: 17px;
}

.button-ac a {
    color: #fff !important;
}

.button-ac {
    padding: 0 5px !important;
    color: #fff !important;
    margin-right: 3px;
}

.button-ac i.fa.fa-search {
    margin-right: 0 !important;
}

@media only screen and (max-width: 570px) {
    .searchs {
        margin-bottom: 14px;
    }
}

@media (min-width: 992px) {
    div.modal-lg {
        max-width: 58%;
    }
}

@media (max-width: 991px) {
    .inbox-left-top div:nth-child(1) {
        position: absolute;
        top: 14px;
        width: 100%;
        left: 0px;
    }

    .reply-store {
        left: -8em !important;
    }

    .inbox-left-top > div > i {
        top: 12px !important;
        left: 32px !important;
    }

    .assign-user-cv {
        display: inline-block;
        margin-left: 13px;
        width: 178px;
        left: 57px !important;
    }

    div#main-info-user {
        position: relative;
        display: inline-block;
    }

    .inbox-image-send {
        background-color: #ffffff;
    }

    div#filter-inboxs {
        position: absolute !important;
        top: 54px;
        left: 4px;
        width: 100%;
    }

    .sub_choose_conversation {
        right: 36px !important;
    }

    .filter-replytu {
        display: none;
    }

    .main-inbox-mobile {
        padding-top: 180px !important;
    }

    .main-inbox {
        padding-top: 80px;
    }

    .inbox-left-bottom2 {
    }

    .inbox-left {
        width: 100% !important;
    }

    span#total-conversation {
        padding-left: 0px !important;
    }

    .inbox-tag-right {
        display: none;
    }

    .inbox-right {
        width: auto !important;
        height: 100% !important;
        float: none !important;
    }

    .inbox-right-top > div {
        position: absolute;
        left: 19px;
        width: 100%;
    }

    div#box-chats {
        position: absolute;
        left: 0;
        width: 100%;
        height: 8em;
        background-color: #ffffff;
    }

    .tab-sticker {
        position: absolute;
        top: -23em !important;
        right: -86px !important;
    }

    .inbox-left-top {
        min-height: auto !important;
        border-bottom: none !important;
    }

    span#back-list-chat {
        float: left;
        display: block;
        padding: 12px 16px 12px 9px;
        font-size: 23px;
        cursor: pointer;
        color: #6f6f6f;
    }
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #f0f3f5;
    border: 1px solid #e4e7ea;
    width: 300px;
}

.suggestions {
    border: 1px solid #e4e7ea;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 300px;
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 100;
    background-color: #f0f3f5;
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #e4e7ea;
}

span#showing_entries {
    display: block;
    float: left;
}

.pagination_main ul.pagination {
    float: right;
}

.help {
    cursor: help;
}

.hear-element {
    margin: 10px 0;
}

.api-input-item {
    margin: 10px 0;
}

.hidden-password {
    float: right;
    margin-left: -25px;
    margin-top: 10px;
    position: relative;
    z-index: 999;
    padding-right: 11px;
    color: #61737f;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.hide {
    display: none;
}

.nav-tabs a.nav-link {
    color: #20a8d8;
}

span.nav-title {
    font-size: 16px;
}

div#modal-head {
    padding-bottom: 0;
    border-bottom: none;
}

h5.modal-title {
    width: 100%;
}

#modal-head.modal-header .close {
    position: absolute;
    top: 5px;
    right: 15px;
    z-index: 99;
}

.upload_file > span {
    position: absolute;
    top: 6px;
    right: -28px;
    font-size: 6px;
}

.upload_file {
    position: relative;
    width: 245px;
}

.upload_file .spinner-border {
    width: 1rem;
    height: 1rem;
}

div#loading_pagename {
    position: absolute;
    right: 29px;
    top: 101px;
}

#loading_pagename .spinner-border {
    width: 1rem;
    height: 1rem;
    font-size: 8px;
}

.companies {
    margin-left: 10px;
}

img.img-avatar-pro {
    margin-right: 15px;
    border: 2px solid #fff;
}

.headpro strong {
    font-size: 19px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
}

.md-list {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    color: #5f5e5e;
    padding-left: 0 !important;
    padding-bottom: 10px;
}

span.user-detail {
    font-weight: 500;
    font-size: 15px;
}

span.md-f {
    display: block;
}

label.lab-f {
    font-size: 23px;
    padding-left: 4px;
    color: #696363;
}

h4.heading_c {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 17px;
    margin-top: 17px;
}

.headpro.card-header {
    background-color: #3898fb;
}

.send-load .spinner-border {
    width: 17px;
    height: 17px;
    margin-right: 6px;
}

.send-load > div {
    margin: auto;
    position: relative;
    top: -2px;
    height: 21px !important;
}

.send-load-page {
    width: 33% !important;
    margin: auto;
}

button.m-ladda {
    width: 72px;
}

.send-load {
    width: 46px;
    margin: auto;
}

.dropdownHears {
    height: 200px;
    overflow: scroll;
}

.sidebar .nav-dropdown-items {
    margin-left: 0px !important;
}

.sidebar .nav-dropdown-items .nav-item .nav-link {
    padding-left: 30px;
}

.sidebar .nav-dropdown-items .nav-item .nav-dropdown-items .nav-item .nav-link {
    padding-left: 58px;
    background-color: #232323;
}

.manager {
    color: #3a9d5d;
    font-size: 18px !important;
    cursor: pointer;
}

form#google-reply-form-search {
    margin-bottom: 26px;
    border-bottom: 1px solid #e6e1e1;
}

.contact_supports a {
    color: red;
    font-size: 14px;
}

.email-member {
    color: #3a9d5d;
    margin-right: 7px;
    font-size: 15px !important;
}

strong.contact_support {
    float: right;
}

strong.contact_support a {
    color: red;
}

.has-error {
    color: red;
}

.sidebar .nav-dropdown-items .nav-item .nav-dropdown-items .nav-item .nav-link:hover {
    padding-left: 58px;
    background-color: #20a8d8;
}

.text-center.loading_data {
    position: fixed;
    top: 6px;
    right: 50%;
    z-index: 9999999;
}

#head-form {
    margin: 16px 21px;
}

.date_report {
    margin-top: 21px;
}

.sort_field {
    cursor: pointer;
}

span.icon_sort {
    margin-left: 14px;
}

span.mr-3 {
    width: 48px;
    height: 48px;
    border-radius: 6px;
}

.requires {
    color: red;
    margin-left: 7px;
}

.pagination_main_lb {
    position: relative;
}

.feedLinks {
    padding: 3px;
    font-size: 12px;
    padding-left: 0;
}

.feedCommentForm1 {
    position: relative;
}

.commentInput {
    width: 100%;
    height: 53px;
    border-radius: 4px;
}

a.gg-fullnames {
    color: #000;
}

#updateBox, .commentInput {
    border: solid 1px #999999;
}

.logo-users {
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-size: 31px;
}

nav.comment_gg {
    margin-top: 30px;
}

li.page-item.cm_next {
    overflow: hidden;
    float: right;
    position: absolute;
    right: 17px;
    border-left: 1px solid #c8ced3;
    border-radius: 5px;
    bottom: 29px;
}

li.page-item.cm_previous a {
    border-radius: 5px;
}

.media.subcm {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #dedede;
}

.media.main_cm {
    margin-bottom: 16px;
}

span.date_cm {
    font-size: 11px;
    margin-left: 9px;
}

.commentSubmit {
    background: #3d7efd;
    border: solid 1px #3d7efd;
    padding: 2px 6px 2px 6px;
    color: #ffffff;
    border-radius: 4px;
    margin-top: 5px;
}

span.star_cm {
    display: inline-block;
    color: #ffc107;
    width: 100%;
    font-size: 10px;
}

span.star_cm i {
    margin-right: 3px;
}

.mt-cm {
    margin-bottom: -1px;
}

.feedCommentForm, .feedCommentGrid {
    clear: both;
    padding: 10px 5px 0px 5px;
    border-top: solid 1px #dedede;
    width: 100%;
    background: #f2f2f2;
}

.pagination_main_lb .loading_data {
    position: absolute;
    top: 0px;
    left: 0px;
}

.tox .tox-notification--warning {
    display: none !important;
}

input.commentSubmit {
    margin-bottom: 9px;
}

span.clearCm {
    font-size: 11px;
    font-weight: bold;
    position: absolute;
    bottom: 75px;
    right: 9px;
    cursor: pointer;
    color: #736e6e;
}

textarea.commentInput {
    padding-right: 23px;
}

.feedCommentForm form {
    position: relative;
}

.TemplateTypeSelect .Select {
    width: 120px;
    display: inline-block;
}

.tox .tox-editor-header {
    z-index: 0 !important;
}

.larger-header {
    vertical-align: middle !important;
    text-align: center;
}

.small-header {
    text-align: center;
}

#table_rating_summary td {
    text-align: center;
}

.btn-search {
    margin-top: 25px;
}

#btn-export-csv {
    display: none;
}

.main-inbox {
    width: 100%;
}

.inbox-left {
    width: 18%;
    height: 100%;
    float: left;
}

.inbox-right {
    width: 69%;
    height: 100%;
    float: left;
}

.inbox-tag-right {
    overflow: hidden;
    width: 13%;
}

img#in-image {
    margin-left: 14px;
}

.inbox-left-top {
    min-height: 116px;
    border-bottom: 1px solid #dadde1;
    padding-top: 35px;
}

.inbox-right-top {
    border-bottom: 1px solid #dadde1;
    height: 116px;
}

i#offline {
    color: red;
}

i#online {
    color: #31c500;
}

.acg span {
    margin-left: 9px;
    font-size: 18px;
    cursor: pointer;
}

.filters-reply {
    position: absolute;
    top: 49px;
    left: 0px;
    padding-left: 13px;
}

.filter-replytu {
    height: 9em;
    border-bottom: 1px solid #dadde1;
}

span.acg {
    background-color: #e0e1e2;
    padding: 2px 5px;
    border-radius: 4px;
    font-weight: 600;
    display: inline-block;
    margin-top: 5px;
    margin-left: 7px;
}

.inbox-left-top input {
    border-radius: 28px;
    height: 38px;
    background-color: #f5f6f7;
    width: 87%;
    margin: auto;
    padding-left: 32px;
}

#filter-inboxs li i {
    float: left;
    display: contents;
}

.inbox-left-top > div > i {
    position: absolute;
    top: 47px;
    left: 45px;
    color: #7f8286;
}

.tab-inbox {
    width: 136px;
    text-align: center;
}

#list-chat-cv {
    height: 36em;
    overflow-y: scroll;
}

div#list-chat {
    border-right: 1px solid #dadde1;
    height: 48em;
}

#list-chat-cv::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#list-chat-cv::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#list-chat-cv::-webkit-scrollbar-thumb {
    background-color: #e3ad01;
}

div#filter-inboxs li span {
    margin-left: 19px;
}

.sub-inbox-right-top img {
    border-radius: 50%;
    width: 57px;
    height: 57px;
}

div#defaultMes {
    height: 41em;
}

.sub-inbox-right-top {
    float: left;
}

.inbox-right-top {
    border-bottom: 1px solid #dadde1;
    height: 116px;
    padding-top: 24px;
    padding-left: 15px;
}

.sub-inbox-right-bottom {
    overflow: hidden;
    font-size: 17px;
    padding-left: 14px;
    font-weight: 700;
    display: inline-block;
}

div#main-info-user {
    position: relative;
}

select#select-assign-user-cv {
    font-size: 13px;
    font-weight: 600;
}

select#filter-assign-user-cv {
    font-size: 16px;
    font-weight: 600;
    width: 91%;
    outline: none;
    border: none;
    color: #000000;
}

select#filter-tag-cv {
    font-size: 16px;
    font-weight: 600;
    width: 91%;
    outline: none;
    border: none;
    color: #000000;
}

.assign-user-cv i {
    margin-left: 9px;
    margin-top: 3px;
}

.assign-user-cv {
    display: inline-block;
    margin-left: 13px;
    position: absolute;
    left: 58px;
    top: 31px;
}

.sub-inbox-right-bottom p {
    margin-bottom: 6px;
}

.assign-user-cv span {
    background: #ebedf0;
    border-color: #ebedf0;
    border-radius: 6px;
    color: #444950;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    height: 30px;
    justify-content: flex-start;
    line-height: 20px;
    max-width: 274px;
    padding: 6px 8px 0;
    cursor: pointer;
}

.inbox-left-bottom {
    clear: both;
}

.inbox-left-bottom1 {
    float: left;
}

.inbox-left-bottom1 img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.inbox-left-bottom2 {
    overflow: hidden;
    padding-left: 13px;
}

.sub-inbox-left-bottom1 {
    float: left;
    width: 100%;
}

.sub-inbox-left-bottom2 {
    font-size: 10px;
    color: rgb(78 78 78 / 75%);
    font-weight: 700;
    text-align: left;
    clear: both;
    padding-top: 6px;
}

ul.tag-suggestions {
    overflow: hidden;
    padding: 0;
}

ul.tag-suggestions .subL-tag {
    list-style: none;
    position: relative;
    padding: 3px 9px;
    background-color: #eaeaea;
    color: #444950;
    border-radius: 5px;
    margin-bottom: 5px;
    clear: both;
    display: inline-block;
}

.sub-inbox-tag-bottom h5 {
    margin-bottom: 16px;
}

.main-lchat:hover .conversation-done {
    display: inline-block;
}

.conversation-done {
    position: absolute;
    top: 6px;
    right: 15px;
    font-size: 26px !important;
    color: #0cbd01;
    display: none;
    cursor: pointer;
}

.main-lchat:hover .conversation-main {
    display: inline-block;
}

.conversation-main {
    position: absolute;
    top: -4px;
    right: 18px;
    font-size: 24px !important;
    color: #60bd70;
    display: none !important;
    cursor: pointer;
}

#forgot-password {
    float: right;
    margin-top: 11px;
}

.check_choose_tag {
    position: relative !important;
    float: left;
    margin-top: 5px !important;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    margin-left: 0 !important;
}

input.addtags {
    margin-top: 15px;
}

div#main-tag-suggestions {
    font-size: 14px;
    font-weight: 400;
    margin-top: 13px;
    margin-bottom: 14px;
}

div#add-tags {
    padding: 3px 12px;
    display: inline-block;
    border-radius: 4px;
    background-color: #e6f2ff;
    color: #1877f2;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

div#add-tags span {
    margin-left: 6px;
}

.sub-inbox-left-bottom1 p {
    margin-bottom: 0;
    font-size: 15px;
    color: #000;
}

.sub-inbox-left-bottom1 span {
    font-size: 13px;
    color: #000000;
}

.list-chat {
    width: 100%;
    padding: 13px 17px;
    cursor: pointer;
    min-height: 84px;
    border-right: 2px solid #ffffff;
    position: relative;
}

.main-lchat {
    position: relative;
}

.list-chat-active, .list-chat:hover {
    background-color: #f5f6f7;
    border-right: 2px solid #0084ff;
}

.sub-inbox-tag-right {
    border-bottom: 1px solid #dadde1;
    height: 116px;
}

.sub-inbox-tag-bottom {
    padding: 14px;
    height: 48em;
    border-left: 1px solid #dadde1;
    padding-right: 0px;
}

.sub_choose_conversation {
    position: absolute;
    bottom: 14px;
    background-color: #ffffff;
    right: 2px;
    width: 55px;
    height: 17px;
    cursor: pointer;
}

span.cgv-tags {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin-top: 6px;
    float: left;
    margin-right: 5px;
}

.tag-acg li:hover .cgv-tags {
    display: none;
}

.tag-acg li:hover .closeSubListTag {
    display: inline-block;
}

span.closeSubListTagModal {
    margin-right: 6px;
    cursor: pointer;
    color: #000000;
    font-weight: 700;
    font-size: 13px;
    width: 9px;
    position: absolute;
    top: 3px;
    left: 10px;
}

.tag-acg li {
    list-style: none;
    position: relative;
    float: left;
    padding: 3px 9px;
    background-color: #eaeaea;
    color: #444950;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
}

span.closeSubListTag {
    margin-right: 6px;
    cursor: pointer;
    color: #000000;
    font-weight: 700;
    font-size: 14px;
    display: none;
    width: 9px;
}

ul.tag-acg {
    overflow: hidden;
    padding: 0;
}

#mschat {
    height: 40em;
    padding-top: 12px;
    padding-left: 15px;
    overflow-y: scroll;
    margin-bottom: 15px;
}

#mschat::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#mschat::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#mschat::-webkit-scrollbar-thumb {
    background-color: #e3ad01;
}

.errMs {
    background-color: #f63c3a !important;
}

.active-mes {
    font-weight: bold;
}

#upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.time-chat {
    width: 100%;
    text-align: center;
    font-weight: 500;
    color: #90949c;
}

.sticker-inbox::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.sticker-inbox::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.sticker-inbox::-webkit-scrollbar-thumb {
    background-color: #e3ad01;
}

.emoji-mart-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.emoji-mart-scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.emoji-mart-scroll::-webkit-scrollbar-thumb {
    background-color: #e3ad01;
}

.user-chat {
    float: left;
    width: 100%;
    margin: 11px 0;
}

.user-chat > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
}

.user-chat span {
    overflow: hidden;
    box-sizing: content-box;
    background-color: #f1f0f0;
    padding: 6px 12px;
    color: #000000;
    border-radius: 20px;
    font-size: 16px;
    display: inline-block;
    margin-left: 8px;
    max-width: 37em;
}

.admin-chat {
    float: right;
    max-width: 27em;
    text-align: right;
    margin: 11px 0;
    clear: both;
}

.user-chat div {
    padding-left: 9px;
    overflow: hidden;
}

.admin-chat span {
    box-sizing: content-box;
    padding: 6px 12px;
    color: #ffffff;
    border-radius: 20px;
    font-size: 16px;
    display: block;
    margin-left: 8px;
    background-color: #0084ff;
    max-width: 37em;
    text-align: left;
}

.inbox-right-send {
    border-top: 1px solid #dadde1;
    position: relative;
    padding: 15px;
    padding-bottom: 0;
}

.inbox-right-send #avatar {
    float: left;
    padding-top: 8px;
}

.inbox-right-send #avatar img {
    width: 35px;
    height: 35px;
}

.admin-chat img {
    cursor: pointer;
    max-height: 250px;
    width: auto;
    border-bottom-left-radius: 1.3em;
    border-top-left-radius: 1.3em;
    border-bottom-right-radius: 1.3em;
    margin: 12px 0px 0px 0px;
    max-width: 290px;
}

.user-chat div img {
    cursor: pointer;
    max-height: 250px;
    width: auto;
    border-bottom-right-radius: 1.3em;
    border-top-right-radius: 1.3em;
    border-bottom-left-radius: 1.3em;
    margin: 12px 0px 0px 0px;
    max-width: 290px;
}

span.remove-tag {
    margin-left: 8px;
    cursor: pointer;
    color: red;
}

.picture-box {
    position: relative;
    width: 71px;
}

.picture-box i {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    color: red;
    font-size: 19px;
}

div#content-send {
    overflow: hidden;
}

#content-send textarea, #content-send textarea:focus {
    width: 86%;
    padding: 6px;
    border: none;
    font-size: 16px;
    resize: none;
    outline: none;
}

.list-stickers {
    float: left;
}

#attach-file label {
    cursor: pointer;
}

a.tab-inbox {
    font-size: 18px;
    font-weight: 500;
    background-color: #f9f7f7;
}

.list-stickers img {
    width: 59px;
}

div#filter-inboxs {
    position: relative;
}

#filter-inboxs ul {
    list-style: none;
    line-height: 52px;
    font-size: 16px;
    font-weight: bolder;
    background-color: #ffffff;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 43px;
    left: 0;
    border-bottom: 1px solid #dddfe2;
    box-shadow: 0 2px 4px 1px rgba(206, 208, 212, .6);
    box-sizing: border-box;
    z-index: 99;
}

div#type-conversation {
    float: left;
    margin-top: 11px;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

#filter-inboxs li i#asign-user {
    float: left;
    display: inline-block;
    padding-top: 12px;
    padding-right: 2px;
}

#filter-inboxs li i#tag-cv {
    float: left;
    display: inline-block;
    padding-top: 12px;
    padding-right: 2px;
}

#type-inboxs li:nth-child(1), #type-inboxs li:nth-child(2) {
    padding: 10px 17px;
}

#type-inboxs li:nth-child(1):hover, #type-inboxs li:nth-child(2):hover {
    background-color: #ffffff;
}

#type-conversation i {
    margin-left: 13px;
    font-size: 18px;
    color: #6f6f6f;
}

span.close-filter {
    position: absolute;
    left: 0px;
    top: 5px;
    cursor: pointer;
    font-size: 18px;
    background-color: #ffffff;
    width: 232px;
    height: 32px;
    padding-top: 2px;
    padding-left: 22px;
}

span#total-conversation {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
}

.acg i {
    margin-right: 8px;
}

.close-filter span {
    font-size: 16px;
    font-weight: bolder;
    margin-left: 17px;
}

#filter-inboxs li {
    cursor: pointer;
    padding: 0 0 0 18px;
}

#filter-inboxs li:hover {
    background-color: #dcdcdc;
}

.active-reply, .active-type-conversation {
    background-color: #dcdcdc;
}

input#check_choose_conversation {
    float: right;
    width: 16px;
    position: unset;
    margin-top: 3px;
    cursor: pointer;
    margin-right: 28px;
}

#filter-inboxs > span > i {
    position: unset;
    float: right;
    margin-right: 9px;
    font-size: 19px;
    margin-top: 12px;
    cursor: pointer;
    padding-right: 9px;
    color: #8e8e8e;
}

#filter-inboxs > span > i.fa-filter, #filter-inboxs > span > i.fa-user-circle-o {
    border-right: 1px solid #c1c1c1;
}

#filter-inboxs > span > i.fa.fa-check-square-o {
    font-size: 21px;
}

.assign-user .select-search {
    width: 100%;
}

#filter-inboxs li i {
    float: left;
    font-size: 18px;
}

.list-stickers-main span {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: darkgoldenrod;
}

.list-stickers-main {
    overflow: hidden;
    margin-bottom: 18px;
    position: relative;
}

.list-stickers-main span:before {
    border-bottom: 1px solid #dcd7d7;
    content: "";
    width: 19em;
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 70px;
}

.sticker-inbox {
    width: 355px;
    height: 434px;
    overflow-y: auto;
}

.tab-sticker {
    position: absolute;
    top: -22em;
    right: 21px;
}

div#send {
    position: absolute;
    top: 22px;
    right: 18px;
    font-size: 15px;
    font-weight: 500;
    color: #4080ff;
    cursor: pointer;
}

div#attach-file {
    position: absolute;
    top: 18px;
    right: 64px;
    font-size: 23px;
    color: #626569;
    cursor: pointer;
}

div#emoji-inbox {
    position: absolute;
    top: 18px;
    right: 101px;
    font-size: 23px;
    color: #626569;
    cursor: pointer;
}

span.sent-by-user {
    display: inline-block;
    float: right;
    clear: both;
    margin-right: 7px;
    margin-top: -9px;
    font-size: 11px;
}

div#reply-stored {
    position: absolute;
    top: 18px;
    right: 137px;
    font-size: 23px;
    color: #626569;
    cursor: pointer;
}

.reply-store {
    position: absolute;
    top: -19em;
    left: -16em;
    width: 17em;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 1px 10px rgb(0 0 0 / 35%);
    border-radius: 2px;
    background-color: #ffffff;
}

#title-reply-store h3 {
    font-size: 17px;
    color: #000000;
    float: left;
}

div#title-reply-store {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .20);
    padding: 8px 12px;
}

#title-reply-store span {
    float: right;
    font-size: 14px;
    color: #0872bd;
}

div#search-reply-store {
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, .20);
    width: 100%;
    padding: 4px 13px;
}

input#ip-search-reply-store {
    overflow: hidden;
    width: 93%;
    border: none;
    outline: none !important;
}

#search-reply-store i.fa.fa-search {
    float: left;
    font-size: 17px;
    color: #888685;
    margin-top: 8px;
}

#list-reply-store ul {
    list-style: none;
    margin-left: -38px;
}

#list-reply-store li {
    border-bottom: 1px solid rgba(0, 0, 0, .10);
    width: 100%;
    padding: 11px 13px;
    overflow: hidden;
}

div#list-reply-store {
    width: 100%;
    height: 15em;
    overflow: auto;
}

#list-reply-store::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#list-reply-store::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#list-reply-store::-webkit-scrollbar-thumb {
    background-color: #e3ad01;
}

#list-reply-store li span {
    font-size: 14px;
    float: left;
    color: #000000;
    width: 294px;
}

#list-reply-store i {
    float: right;
}

#list-reply-store i {
    float: right;
    margin-left: 14px;
    margin-top: 12px;
    font-size: 17px;
}

div#main-list-reply-store {
    width: 100%;
}

.contentReply, .contentReply:focus {
    width: 100%;
    padding: 13px;
    border: none;
    font-size: 16px;
    resize: none;
    outline: none;
    height: 284px;
}

div#contentReply {
    width: 100%;
    height: 17em;
}

div#action-reply-store {
    text-align: right;
    border-top: 1px solid #dddfe2;
    padding-top: 13px;
}

#titleReply input {
    font-size: 16px;
    border: none;
    color: #000000;
}

span#titleReply {
    border-bottom: 1px solid #dddfe2;
    overflow: hidden;
    display: block;
}

#list-reply-store li span b {
    width: 100%;
    display: inline-block;
}

ul#list-tags {
    position: absolute;
    background-color: #ffffff;
    width: 93%;
    list-style: none;
    padding-left: 0px;
    height: 242px;
    overflow-y: auto;
}

ul#list-tags::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

ul#list-tags::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

ul#list-tags::-webkit-scrollbar-thumb {
    background-color: #e3ad01;
}

#list-tags li {
    padding: 12px 13px;
    border-bottom: 1px solid #e2e2e2;
    cursor: pointer;
}

#list-tags li:hover {
    background-color: #d4d2d2;
}

#list-tags li:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: red;
    display: inline-block;
    border-radius: 10px;
    margin-right: 8px;
}

#list-tags-choose {
    list-style: none;
    padding-left: 0px;
}

#list-tags-choose li {
    list-style: none;
    position: relative;
    float: left;
    padding: 3px 9px;
    background-color: #eaeaea;
    color: #444950;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding-left: 23px;
}

/*the container must be positioned relative:*/
.autocomplete {
    position: relative;
    display: inline-block;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
    background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
    background-color: DodgerBlue !important;
    color: #ffffff;
}

.tag-suggestions li {
    width: 212px;
}

a.link-comment, a.link-comment:hover {
    color: #ffffff;
    text-decoration: underline;
}

.user-chat a.link-comment, .user-chat a.link-comment:hover {
    color: #000000;
    text-decoration: underline;
}

div#tag-inboxs {
    height: 44em;
    overflow-y: scroll;
}

div#tag-inboxs::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

div#tag-inboxs::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

div#tag-inboxs::-webkit-scrollbar-thumb {
    background-color: #e3ad01;
}

#user-name-report {
    vertical-align: inherit !important;
}

i.detail-report.fa.fa-file-text-o {
    cursor: pointer;
    color: #316ffb;
}

#day-detail-report {
    vertical-align: inherit !important;
}

.ctv-paginate span#showing_entries {
    display: none;
}

.deleteGroup {
    cursor: pointer;
    font-size: 17px;
    color: red;
}

span.listUsersGroup {
    display: block;
}

table#table_fanchat {
    margin-top: 19px;
}

#table_fanchat td {
    vertical-align: middle;
}

#table_rating_summary th {
    vertical-align: middle;
}

.detail-report-group-game {
    margin-top: 14em !important;
}

input#from-search-email {
    width: 30%;
    flex: none;
}

input#search-page-name {
    width: 30%;
    flex: none;
}

i.fa.fa-refresh.reload-page {
    position: absolute;
    top: 8px;
    left: -31px;
    cursor: pointer;
    font-size: 19px;
    color: #0035f7;
}

.switch_middle{
    vertical-align: middle;
}

.google-btn {
    width: 100%;
    height: 40px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    margin-top: 10px

}
.google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 38px;
    border-radius: 2px;
    background-color: #fff;
}
.google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
}
.btn-text {
    float: right;
    margin: 10px 70px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
}
